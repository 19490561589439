<template>
  <div v-loading.fullscreen.lock="loading" id="welcome_explanation_page">
		<inner-page-title title="How Sharo works" :back-navigation="false" action="Close" @actionClicked="onClose"></inner-page-title>
		<div style="max-width: 600px; height: calc(100vh - 100px); display: flex; flex-direction: column;" class="container">
			<div class="information-tabs">
        <div v-if="active_tab == 0">
          <div>
            <img :src="publicPath + 'img/welcome-search.png'">
            <span class="tab-title">Search carpoolers</span>
            <span class="tab-desc">Search carpoolers that live near you commuting to college.</span>
          </div>
        </div>
        <div v-if="active_tab == 1">
          <div>
            <img :src="publicPath + 'img/welcome-profile.png'">
            <span class="tab-title">Send a them a request</span>
            <span class="tab-desc">They can review your profile and accept or reject your connection request.</span>
          </div>
        </div>
        <div v-if="active_tab == 2">
          <div>
            <img :src="publicPath + 'img/welcome-schedule.png'">
            <span class="tab-title">Arrange trips</span>
            <span class="tab-desc">Once you're connected, arrange pickup times and location on WhatsApp. You can then book upcoming trips in the schedule.</span>
          </div>
        </div>
      </div>
      <div class="next-button-container">
        <el-button v-if="active_tab > 0" type="info" class="secondary-color" @click="previousPage" round plain>Back</el-button>
        <el-button type="primary" class="secondary-color" @click="nextPage" round>{{ buttons_text[active_tab] }}</el-button>
      </div>
      <div style="text-align: center; margin-top: 5px; margin-bottom: -20px">
        <span>Page {{ active_tab + 1 }} of 3</span>
      </div>
		</div>
  </div>
</template>

<script>
export default {
 data: () => ({
   loading: false,
   active_tab: 0,
   buttons_text: ['Next', 'Next', 'Finish'],
   publicPath: process.env.BASE_URL
 }),
 methods: {
   onClose() {
     return this.$router.push('/');
   },

   nextPage() {
     if (this.active_tab > 1) {
       return this.$router.push('/');
     }

     this.loading = true;
     setTimeout(() => {
       this.loading = false;
       this.active_tab = this.active_tab + 1;
     }, 750);
   },

   previousPage() {
     if (this.active_tab < 1) {
       return;
     }

     this.loading = true;
     setTimeout(() => {
       this.loading = false;
       this.active_tab = this.active_tab - 1;
     }, 350);
   }
 }
}
</script>

<style lang="scss" scoped>
  .next-button-container {
    text-align: center;
  }

  .information-tabs {
    flex: 1;

    & > div {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      & > div {
        text-align: center;
        max-width: 350px;

        img {
          width: 100%;
          box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.1);
          border-radius: 5px;
          border: 1px solid rgba(0,0,0,0.1);
          margin-bottom: 10px;
        }

        span {
          display: block;
        }

        .tab-title {
          font-size: 20px;
          margin-bottom: 3px;
        }
      }
    }
  }
</style>