var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "welcome_explanation_page" }
    },
    [
      _c("inner-page-title", {
        attrs: {
          title: "How Sharo works",
          "back-navigation": false,
          action: "Close"
        },
        on: { actionClicked: _vm.onClose }
      }),
      _c(
        "div",
        {
          staticClass: "container",
          staticStyle: {
            "max-width": "600px",
            height: "calc(100vh - 100px)",
            display: "flex",
            "flex-direction": "column"
          }
        },
        [
          _c("div", { staticClass: "information-tabs" }, [
            _vm.active_tab == 0
              ? _c("div", [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.publicPath + "img/welcome-search.png" }
                    }),
                    _c("span", { staticClass: "tab-title" }, [
                      _vm._v("Search carpoolers")
                    ]),
                    _c("span", { staticClass: "tab-desc" }, [
                      _vm._v(
                        "Search carpoolers that live near you commuting to college."
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.active_tab == 1
              ? _c("div", [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.publicPath + "img/welcome-profile.png" }
                    }),
                    _c("span", { staticClass: "tab-title" }, [
                      _vm._v("Send a them a request")
                    ]),
                    _c("span", { staticClass: "tab-desc" }, [
                      _vm._v(
                        "They can review your profile and accept or reject your connection request."
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.active_tab == 2
              ? _c("div", [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: _vm.publicPath + "img/welcome-schedule.png"
                      }
                    }),
                    _c("span", { staticClass: "tab-title" }, [
                      _vm._v("Arrange trips")
                    ]),
                    _c("span", { staticClass: "tab-desc" }, [
                      _vm._v(
                        "Once you're connected, arrange pickup times and location on WhatsApp. You can then book upcoming trips in the schedule."
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "next-button-container" },
            [
              _vm.active_tab > 0
                ? _c(
                    "el-button",
                    {
                      staticClass: "secondary-color",
                      attrs: { type: "info", round: "", plain: "" },
                      on: { click: _vm.previousPage }
                    },
                    [_vm._v("Back")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "secondary-color",
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.nextPage }
                },
                [_vm._v(_vm._s(_vm.buttons_text[_vm.active_tab]))]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "margin-top": "5px",
                "margin-bottom": "-20px"
              }
            },
            [
              _c("span", [
                _vm._v("Page " + _vm._s(_vm.active_tab + 1) + " of 3")
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }